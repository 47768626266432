<template>
    <standard-dialog v-model='dialog' :max-width="700">
        <template v-slot:title>
            Set {{ cyclePluralName }} for {{ locName }}
        </template>
        <template v-slot:content>
            <v-form ref="form" :lazy-validation="true">
                <v-container v-if="filteredCycles">
                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                type="number"
                                v-model="requiredCount"
                                :label="'Target ' + cyclePluralName"
                                :rules="[$rules.min(0)]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                type="number"
                                v-model="priority"
                                :label="locationName + ' Priority'"
                                :rules="[$rules.min(1)]"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-for="(plannedCycle, index) in plannedCycles" :key="index">
                        <v-col cols="6">
                            <v-select
                                v-if="!!plannedCycle.plannedCycleId && !isManualCycle(plannedCycle)"
                                :items="cycles"
                                item-value="id"
                                item-text="name"
                                label="Cycle Type"
                                v-model="plannedCycle.cycleId"
                                :menu-props="{ dark: true }"
                                :rules="[
                                        $rules.required(),
                                        $rules.custom(() => hasTasks(plannedCycle), 'Cycle has no tasks'),
                                    ]"
                                :disabled="!!plannedCycle.plannedCycleId"
                                :readonly="!!plannedCycle.plannedCycleId"
                            ></v-select>
                            <v-select
                                :items="filteredActiveCycles"
                                item-value="id"
                                item-text="name"
                                label="Cycle Type"
                                v-model="plannedCycle.cycleId"
                                :menu-props="{ dark: true }"
                                :rules="[
                                        $rules.required(),
                                        $rules.custom(() => hasTasks(plannedCycle), 'Cycle has no tasks'),
                                    ]"
                                :disabled="!!plannedCycle.plannedCycleId"
                                :readonly="!!plannedCycle.plannedCycleId"
                                v-else-if="!isManualCycle(plannedCycle)"
                            ></v-select>
                            <v-text-field v-else label="Cycle Type" :value="getManualCycleName(plannedCycle)" :disabled="true" :readonly="true">

                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-access-control
                                :roles="[AppRoles.WeekPlanner]"
                                v-if="!plannedCycle.cycle.lockedInPast"
                            >
                                <template #forbidden>
                                    <div />
                                </template>
                                <v-btn text @click="removeCycle(plannedCycle)" class="mt-3">
                                    <v-icon left>mdi-delete</v-icon>Delete
                                </v-btn>
                                <v-btn text @click="addCycle(index)" class="mt-3">
                                    <v-icon left>mdi-plus</v-icon>Add before
                                </v-btn>
                            </v-access-control>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col :cols="12">
                            <v-access-control :roles="[AppRoles.WeekPlanner]">
                                <template #forbidden>
                                    <div />
                                </template>
                                <v-btn color="primary" @click="addCycle()">
                                    <v-icon left>mdi-plus</v-icon>Add Cycle
                                </v-btn>
                            </v-access-control>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col :cols="12">
                            <v-alert color="error">
                                <strong>Warning!</strong> Inserting cycles into the plan may cause later cycles to
                                move
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </template>
        <template v-slot:actions>
            <v-access-control :roles="[AppRoles.WeekPlanner]">
                <template #forbidden>
                    <div />
                </template>
                <v-btn color="red" text v-if="canBeRemoved" @click="del()">Remove {{ locationName }}</v-btn>
            </v-access-control>
            <v-spacer></v-spacer>
            <v-btn color="gray" text @click="dialog = false">Cancel</v-btn>
            <v-access-control :roles="[AppRoles.WeekPlanner]">
                <template #offline>
                    <offline-btn text></offline-btn>
                </template>

                <template #forbidden>
                    <no-permission-btn text label="Select"></no-permission-btn>
                </template>
                <v-btn color="primary" text @click="select()">Select</v-btn>
            </v-access-control>
        </template>
    </standard-dialog>
</template>
<script>
import Cycles from '@/lib/data/Cycles';
import { Roles } from '@/lib/Security';
import { CycleType } from '@/models/api/Enums/cycle-type';

export default {
    props: ['locationName', 'cyclePluralName'],
    data() {
        return {
            plannedCycles: [],
            cycles: null,
            dialog: false,
            location: null,
            requiredCount: 0,
            priority: 1,
            canBeRemoved: false,
            departmentId: null,
            AppRoles: Roles,
        };
    },
    async created() {
        this.cycles = await Cycles.get(['Tasks']);
    },
    methods: {
        addCycle(index) {
            if (index || index === 0) {
                this.plannedCycles.splice(index, 0, {
                    plannedCycleId: null,
                    cycleId: this.defaultCycle,
                    cycle: {},
                });
            } else {
                this.plannedCycles.push({ plannedCycleId: null, cycleId: this.defaultCycle, cycle: {} });
            }
        },
        removeCycle(c) {
            this.plannedCycles = this.plannedCycles.filter((x) => x !== c);
        },
        show(location, departmentId, availableCycles, current, requiredCount, priority, canRemove) {
            var forThisWeek = current.filter((x) => !x.isForPreviousPlan);
            this.plannedCycles = forThisWeek.map((x) => ({
                plannedCycleId: x.id,
                cycleId: x.taskType.id,
                cycle: x,
            }));
            this.canBeRemoved = canRemove;
            this.requiredCount = requiredCount || 0;
            this.location = location;
            this.departmentId = departmentId;
            this.priority = priority;
            this.defaultCycle = availableCycles.length ? availableCycles[0] : null;

            setTimeout(() => {
                this.dialog = true;
                setTimeout(() => this.$refs.form.resetValidation(), 1);
            }, 1);
        },
        select() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.$emit('selected', {
                location: this.location,
                data: {
                    cycles: this.plannedCycles,
                    priority: this.priority,
                    required: this.requiredCount,
                },
            });
            this.dialog = false;
        },
        del() {
            this.$emit('delete', this.location);
            this.dialog = false;
        },
        hasTasks(plannedCycle) {
            var id = plannedCycle.cycleId;
            if (!id) {
                return false;
            }
            var matching = this.cycles.filter((x) => x.id == id);
            var hasTasks = matching.length && matching[0].tasks && matching[0].tasks.length;
            return !hasTasks;
        },
        isManualCycle(plannedCycle) {
            if(!this.filteredCycles)
                return true;

            if(plannedCycle.plannedCycleId != null && !this.filteredCycles.some(fc=>fc.id == plannedCycle.cycleId))
                return true;

            return false;
        },
        getManualCycleName(plannedCycle) {
            if(!this.isManualCycle(plannedCycle))
                return '';

            return plannedCycle.cycle.taskName;
        }
    },
    computed: {
        filteredActiveCycles() {
            if (!this.cycles) return;
            return this.filteredCycles.filter(
                (x) => !x.isArchived
            );
        },
        filteredCycles() {
            if (!this.cycles) return;
            return this.cycles.filter(
                (x) => x.departmentId == this.departmentId && x.cycleType == CycleType.Duration
            );
        },
        locName() {
            if (this.location == null) {
                return '';
            }
            return this.location.name;
        },
    },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-5"},[(_vm.data)?_c('v-card',[_c('v-card-title',[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm._f("term")('Equipment')))]),_c('v-btn',{staticClass:"ml-3",attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.create()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("Add ")],1),(!!_vm.data && _vm.data.length === 0)?_c('v-menu',{attrs:{"max-width":"310px","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"small":"","color":"secondary"}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-upload")]),_vm._v("Import ")],1)]}}],null,false,1904447986),model:{value:(_vm.importDialog),callback:function ($$v) {_vm.importDialog=$$v},expression:"importDialog"}},[_c('v-card',[_c('v-card-text',[_c('input',{ref:"inputUpload",attrs:{"type":"file","accept":"text/csv"},on:{"change":function($event){return _vm.getFile($event)}}})]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"secondary","disabled":_vm.file === null},on:{"click":_vm.importData}},[_vm._v("Upload")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.importDialog = false}}},[_vm._v("cancel")])],1)],1)],1):_vm._e(),(!!_vm.data && _vm.data.length)?_c('v-btn',{staticClass:"ml-1",attrs:{"small":"","color":"gray"},on:{"click":function($event){return _vm.exportData()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v("Export ")],1):_vm._e(),_c('v-spacer'),_c('admin-sandpit-visibility-selector')],1),_c('v-data-table',{attrs:{"disable-pagination":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.filteredActiveEquipment},scopedSlots:_vm._u([{key:"item.imageUrl",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"contain":"","src":item.imageUrl,"max-height":74,"max-width":120}})]}},{key:"item.showConflicts",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.showConflicts),callback:function ($$v) {_vm.$set(item, "showConflicts", $$v)},expression:"item.showConflicts"}})]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"float-right",attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('admin-sandpit-item-name',{attrs:{"is-sandpit":item.isSandpitUseOnly}},[_vm._v(_vm._s(item.name))])]}}],null,false,2368544734)}),(_vm.filteredArchivedEquipment.length > 0)?_c('v-card-title',[_c('h2',{staticClass:"subtitle-1"},[_c('v-icon',{attrs:{"color":"primary","left":""}},[_vm._v("mdi-archive-outline")]),_vm._v("Archived "+_vm._s(_vm._f("term")('Equipment')))],1)]):_vm._e(),(_vm.filteredArchivedEquipment.length > 0)?_c('v-data-table',{attrs:{"disable-pagination":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.filteredArchivedEquipment},scopedSlots:_vm._u([{key:"item.imageUrl",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"contain":"","src":item.imageUrl,"max-height":74,"max-width":120}})]}},{key:"item.showConflicts",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.showConflicts),callback:function ($$v) {_vm.$set(item, "showConflicts", $$v)},expression:"item.showConflicts"}})]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"float-right",attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('admin-sandpit-item-name',{attrs:{"is-sandpit":item.isSandpitUseOnly}},[_vm._v(_vm._s(item.name))])]}}],null,false,2368544734)}):_vm._e()],1):_vm._e(),(_vm.selected !== null)?_c('edit-equipment-dialog',{attrs:{"showModal":_vm.showModal,"equipment":_vm.selected,"editing-equipment":_vm.editingSelected,"equipmentRoles":_vm.getAvailableRolesForEquipment(_vm.selected),"departments":_vm.getAvailableDepartmentsForEquipment(_vm.selected)},on:{"deleteEquipment":_vm.onDeleteEquipment,"archiveEquipment":_vm.onArchiveEquipment,"restoreEquipment":_vm.onRestoreEquipment,"saveEquipment":_vm.onSaveEquipment,"cancelEdit":_vm.onCancelEdit}}):_vm._e(),_c('confirm',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
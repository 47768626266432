<template>
    <div class='pa-5'>
        <v-card v-if='departments.length > 0'>
            <v-card-title>
                <h1 class='title'>Actuals Definition Groups</h1>
                <template v-if='selectedDepartment'>
                    <v-btn small color='secondary' class='ml-3' @click='create()'>
                        <v-icon left>mdi-plus</v-icon>
                        Add
                    </v-btn>
                    <v-menu
                        v-model='importDialog'
                        max-width='310px'
                        :close-on-content-click='false'
                        v-if='!!selectedDepartment'
                    >
                        <template v-slot:activator='{ on }'>
                            <v-btn v-on='on' small class='ml-1' color='secondary'>
                                <v-icon left>mdi-upload</v-icon>
                                Import
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-text>
                                <input ref='inputUpload' type='file' accept='text/json' @change='getFile($event)' />
                            </v-card-text>
                            <v-card-actions>
                                <v-btn color='secondary' @click='importData' :disabled='!file'>Upload</v-btn>
                                <v-btn text @click='importDialog = false'>cancel</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-menu>
                    <v-btn
                        small
                        class='ml-1'
                        color='gray'
                        v-if='!!selectedDepartment && data.length'
                        @click='exportData'
                    >
                        <v-icon left>mdi-download</v-icon>
                        Export
                    </v-btn>
                </template>
                <v-spacer></v-spacer>
                <v-select
                    v-if='departments'
                    :items='departments'
                    item-value='id'
                    item-text='name'
                    v-model='selectedDepartment'
                    label='Department'
                    single-line
                    hide-details
                    class='admin-table-filter'
                    :menu-props='{ dark: true }'
                >
                    <template v-slot:item='data'>
                        <template v-if='data.item.isGroup'>
                            <v-list-item-content
                                class='caption'
                                @click='$event.stopPropagation()'
                                v-text='data.item.name'
                            ></v-list-item-content>
                        </template>
                        <template v-else>
                            <v-list-item-content class='pl-5' v-text='data.item.name'></v-list-item-content>
                        </template>
                    </template>
                </v-select>
            </v-card-title>
            <v-data-table disable-pagination hide-default-footer :headers='headers' :items='data' v-if='!!selectedDepartment'>
                <template v-slot:item='{ item }'>
                    <tr>
                        <td>{{ item.groupName }}</td>
                        <td>
                            <ul class='mt-4 mb-4' v-if='item.actualGroupDefinitions'>
                                <li v-for='(definition, index) in item.actualGroupDefinitions' :key='index'>
                                    {{ definition.groupDefinitionName }}
                                </li>
                            </ul>
                        </td>
                        <td>
                            <ul class='mt-4 mb-4' v-if='item.taskTypes'>
                                <li v-for='(task, index) in item.taskTypes' :key='index'>
                                    {{ task.taskTypeName }}
                                </li>
                            </ul>
                        </td>
                        <td>
                            <div class='no-wrap'>
                                <v-btn text icon @click='edit(item, false)'>
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </div>
                        </td>
                    </tr>
                </template>
            </v-data-table>
            <empty-state
                v-if="!selectedDepartment"
                icon="mdi-alert-circle-outline"
                :message="'Select a ' + $termSync('department')"
            ></empty-state>
        </v-card>
        <standard-dialog :render='!!selected' :value='showModal' :max-width='900' persistent>
            <template v-slot:title>
                <span v-if='selected.id'>Edit</span>
                <span v-else>Create</span>
                <span class='ml-1'>{{ 'Actuals Definition Group' | term }}</span>
            </template>
            <template v-slot:content>
                <v-form ref='form' :lazy-validation='true'>
                    <v-container fluid>
                        <v-row v-if='selected.id'>
                            <v-col cols='12'>
                                <v-text-field
                                    label='Id'
                                    v-model='selected.id'
                                    disabled
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols='12'>
                                <v-text-field
                                    autocomplete='off'
                                    label='Name'
                                    :rules='[$rules.required(), $rules.maxLength(50)]'
                                    v-model='selected.name'
                                    counter='50'
                                    :disabled='!selected.canAlterNameOrDelete' :loading='selected.loadingCanAlterNameOrDelete'
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class='mt-8'>
                            <v-select
                                v-model='selected.selectedDefinitions'
                                :items='definitionsList'
                                item-text='groupDefinitionName'
                                label='Assigned Actuals Definitions'
                                multiple
                                outlined
                                return-object
                                hide-details
                            >
                                <template v-slot:selection='{ index }'>
                  <span v-if='index === 0'>
                    {{ selected.selectedDefinitions.length }} selected
                  </span>
                                </template>

                            </v-select>
                        </v-row>

                        <v-row v-if='selected.selectedDefinitions'>
                            <v-col cols='12'>
                                <v-data-table disable-pagination hide-default-footer
                                              :headers='definitionListHeaders'
                                              :items='selected.selectedDefinitions'
                                >
                                    <template v-slot:item='{ item, index }'>
                                        <tr>
                                            <td>{{ item.groupDefinitionName }}</td>
                                            <td style='text-align: right'>
                                                <v-btn text icon @click="moveItem(index, 'up')">
                                                    <v-icon>mdi-arrow-up</v-icon>
                                                </v-btn>
                                                <v-btn text icon @click="moveItem(index, 'down')">
                                                    <v-icon>mdi-arrow-down</v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>

                        <v-row class='mt-15'>
                            <v-select
                                v-model='selected.assignedTaskTypeIds'
                                :items='availableTaskTypes'
                                item-text='name'
                                item-value='id'
                                chips
                                label='Assigned Task Types'
                                multiple
                                outlined
                            ></v-select>
                        </v-row>
                    </v-container>

                </v-form>
                <v-alert
                    v-if='!selected.canAlterNameOrDelete && !selected.loadingCanAlterNameOrDelete'
                    color='warning'
                    class="white--text">
                    This group is in use and its name cannot be altered nor it deleted.
                </v-alert>
            </template>
            <template v-slot:actions>
                <v-btn color='red' text v-if='selected && selected.id' @click='del()' :disabled='!selected.canAlterNameOrDelete' :loading='selected.loadingCanAlterNameOrDelete'>Delete</v-btn>
                <v-spacer></v-spacer>
                <v-btn color='gray' text @click='showModal = false'>Cancel</v-btn>
                <v-btn color='primary' text @click='save()'>Save</v-btn>
            </template>
            <template v-slot:offline-actions>
                <v-spacer></v-spacer>
                <v-btn color='gray' text @click='showModal = false'>Cancel</v-btn>
                <offline-btn text></offline-btn>
            </template>
        </standard-dialog>
        <confirm ref='confirm'></confirm>
    </div>
</template>
<script>
import { EventBus, Events } from '@/lib/EventBus';
import EquipmentRoles from '@/lib/data/EquipmentRoles';
import DownloadHelper from '@/lib/DownloadHelper';
import Actuals from '@/lib/data/Actuals';
import { mapState } from 'pinia';
import TaskTypes from '@/lib/data/TaskTypes';
import MineAreas from '@/lib/data/MineAreas';

export default {
    data() {
        return {
            data: [],
            showModal: false,
            departments: [],
            selectedDepartment: null,
            selected: {},
            importDialog: false,
            file: null,
            headers: [
                { text: 'Group Name', value: 'name', width: '30%' },
                { text: 'Assigned Definitions', value: 'name', width: '30%' },
                { text: 'Assigned Tasks', value: '', width: '38%' },
                { text: '', value: 'id', width: '2%', align: 'right' }
            ],
            definitionListHeaders: [
                { text: 'Actuals Definitions Sort Order', value: 'groupDefinitionName', width: '80%', sortable: false },
                { text: '', value: 'id', width: '20%', align: 'right' }
            ],
            definitionsList: null,
            taskTypeList: null
        };
    },
    async created() {
        await this.loadDepartments();
    },
    destroyed() {
    },
    methods: {
        moveItem(index, direction) {
            if (direction === 'up' && index === 0)
                return;

            if (direction === 'down' && index === this.selected.selectedDefinitions.length - 1)
                return;

            let first = direction === 'up' ? this.selected.selectedDefinitions[index - 1] : this.selected.selectedDefinitions[index];
            let second = direction === 'up' ? this.selected.selectedDefinitions[index] : this.selected.selectedDefinitions[index + 1];

            this.selected.selectedDefinitions[direction === 'up' ? index - 1 : index] = second;
            this.selected.selectedDefinitions[direction === 'up' ? index : index + 1] = first;

            this.selected.selectedDefinitions = this.selected.selectedDefinitions.slice();
        },
        async loadDepartments() {
            const mineAreas = await MineAreas.get(['Departments']);

            var departments = [];
            for (let mine of mineAreas) {
                departments.push({ id: mine.id, name: mine.name ?? '', isGroup: true, mineAreaId: mine.id });
                for (let dept of mine.departments) {
                    departments.push({
                        id: dept.id,
                        mineAreaId: mine.id,
                        name: dept.name ?? '',
                        isGroup: false
                    });
                }
            }
            this.departments = departments;
        },
        async loadDepartmentDefinitions() {
            var data = await Actuals.getGroups(this.selectedDepartment, []);

            this.taskTypeList = (await TaskTypes.getByDepartment(this.selectedDepartment)).sort((a, b) => a.name.localeCompare(b.name));
            this.definitionsList = (await Actuals.getDefinitions(this.selectedDepartment)).sort((a, b) => a.groupDefinitionName.localeCompare(b.groupDefinitionName));

            this.data = data;
        },
        create() {
            var displayOrder = this.data.length ? this.data[this.data.length - 1].displayOrder + 1 : 1;
            this.edit({}, true);
        },
        edit(item, isNew) {
            this.selected = {
                id: item.groupId,
                name: item.groupName,
                selectedDefinitions: item.actualGroupDefinitions ? item.actualGroupDefinitions.map(x => {
                    return {
                        groupDefinitionId: x.groupDefinitionId,
                        groupDefinitionName: x.groupDefinitionName,
                        sortOrder: x.sortOrder
                    };
                }).sort((a, b) => a.sortOrder - b.sortOrder) : [],
                assignedTaskTypeIds: item.taskTypes ? item.taskTypes.map(x => x.taskTypeId) : [],
                loadingCanAlterNameOrDelete: !isNew,
                canAlterNameOrDelete: isNew
            };

            if(!isNew){
                this.selected.loadingCanAlterNameOrDelete = true;
                Actuals.groupHasData(this.selectedDepartment, this.selected.id).then(response => {
                    if(this.selected?.id === item.groupId)
                        this.selected.canAlterNameOrDelete = response.hasData === false;
                }).finally(() => {
                    if(this.selected?.id === item.groupId)
                        this.selected.loadingCanAlterNameOrDelete = false;
                });
            }

            this.showModal = true;
            setTimeout(() => this.$refs.form.resetValidation(), 1);
        },
        async del() {
            if (await this.$refs.confirm.openAsDeleteResource(this.$termSync('Actuals Group'), {})) {
                await Actuals.deleteGroup(this.selectedDepartment, this.selected.id);
                EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Actuals Group')} Deleted`);
                this.showModal = false;
                await this.loadDepartmentDefinitions();
            }
        },
        async save() {
            if (!this.$refs.form.validate()) {
                return;
            }

            let newSortOrder = 1;

            let upsertCommand = {
                id: this.selected.id,
                name: this.selected.name,
                actualsDefinitions: this.selected.selectedDefinitions.map(x => {
                    return {
                        id: x.groupDefinitionId,
                        sortOrder: newSortOrder++
                    };
                }),
                taskTypeIds: this.selected.assignedTaskTypeIds,
                departmentId: this.selectedDepartment
            };
            
            await Actuals.upsertGroup(upsertCommand);

            EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Actuals Group')} Saved`);
            this.showModal = false;
            await this.loadDepartmentDefinitions();
        },
        async exportData() {
            const result = await Actuals.exportGroups(this.selectedDepartment);
            var csv = result.csvFile;
            var blob = DownloadHelper.makeBlobFromFileString(csv);
            var departmentName = this.selectedDepartmentModel?.name?.replace(/[/\\?%*:|"<>]/g, '') ?? "Unknown_Department";
            DownloadHelper.download(`ActualsGroups-${departmentName}.csv`, blob);
        },
        async importData() {
            try {
                this.$wait.start('saving');
                this.uploadResult = null;
                await Actuals.importGroups(this.selectedDepartment, this.file);
                this.importDialog = false;
                await this.loadDepartmentDefinitions();
            } finally {
                this.$wait.end('saving');
            }
        },
        async getFile(e) {
            this.file = e.target.files[0];
        }
    },
    computed: {
        selectedDepartmentModel() {
            return this.departments.find(d => d.id === this.selectedDepartment) ?? null;
        },
        availableTaskTypes() {
            if(this.selected == null)
                return [];

            return this.taskTypeList.filter(x => !x.isArchived || this.selected.assignedTaskTypeIds.includes(x.id));
        }
    },
    watch: {
        async selectedDepartment(newValue){
            await this.loadDepartmentDefinitions();
        }
    }
};
</script>
<style lang='scss' scoped>
</style>

<template>
    <div class="pa-5">
        <v-card v-if="data">
            <v-card-title>
                <h1 class="title">{{ 'Staff/Crew Roles' | term }}</h1>
                <v-btn small color="secondary" class="ml-3" @click="create()">
                    <v-icon left>mdi-plus</v-icon>Add
                </v-btn>
                <v-menu
                    v-model="importDialog"
                    max-width="310px"
                    :close-on-content-click="false"
                    v-if="!!data && !data.length"
                >
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" small class="ml-1" color="secondary">
                            <v-icon left>mdi-upload</v-icon>Import
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-text>
                            <input ref="inputUpload" type="file" accept="text/csv" @change="getFile($event)" />
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="secondary" :disabled="file === null" @click="importData">Upload</v-btn>
                            <v-btn text @click="importDialog = false">cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
                <v-btn small class="ml-1" color="gray" v-if="!!data && data.length" @click="exportData()">
                    <v-icon left>mdi-download</v-icon>Export
                </v-btn>
                <v-btn small color="primary" class="ml-3" v-if="dirty" @click="saveList()">
                    <v-icon left>mdi-check</v-icon>Save
                </v-btn>
                <v-btn small color="grey" class="ml-3" v-if="dirty" @click="loadData()">
                    <v-icon left>mdi-close</v-icon>Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <admin-sandpit-visibility-selector></admin-sandpit-visibility-selector>
            </v-card-title>
            <v-data-table disable-pagination hide-default-footer :headers="headers" :items="sortedAndFilteredActive">
                <template v-slot:item="{ item }">
                    <tr :class="{ dirty: item.dirty }">
                        <td><admin-sandpit-item-name :is-sandpit="item.isSandpitUseOnly">{{ item.name }}</admin-sandpit-item-name></td>
                        <td>
                            <v-icon v-if="item.isSupervisor">mdi-check</v-icon>
                        </td>
                        <td>
                            <div class="no-wrap">
                                {{ item.displayOrder }}
                                <v-btn text icon @click="up(item)">
                                    <v-icon>mdi-arrow-up</v-icon>
                                </v-btn>
                                <v-btn text icon @click="down(item)">
                                    <v-icon>mdi-arrow-down</v-icon>
                                </v-btn>
                                <v-btn text icon @click="edit(item)">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </div>
                        </td>
                    </tr>
                </template>
            </v-data-table>
            <v-card-title v-if="sortedAndFilteredArchived.length > 0">
                <h2 class='subtitle-1'><v-icon color="primary" left>mdi-archive-outline</v-icon>Archived {{ 'Staff/Crew Roles' | termPlural }}</h2>
            </v-card-title>
            <v-data-table
                disable-pagination
                hide-default-footer
                :headers="headers"
                :items="sortedAndFilteredArchived"
                v-if="sortedAndFilteredArchived.length > 0"
            >
                <template v-slot:item="{ item }">
                    <tr :class="{ dirty: item.dirty }">
                        <td><admin-sandpit-item-name :is-sandpit="item.isSandpitUseOnly">{{ item.name }}</admin-sandpit-item-name></td>
                        <td>
                            <v-icon v-if="item.isSupervisor">mdi-check</v-icon>
                        </td>
                        <td>
                            <div class="no-wrap">
                                {{ item.displayOrder }}
                                <v-btn text icon @click="up(item)">
                                    <v-icon>mdi-arrow-up</v-icon>
                                </v-btn>
                                <v-btn text icon @click="down(item)">
                                    <v-icon>mdi-arrow-down</v-icon>
                                </v-btn>
                                <v-btn text icon @click="edit(item)">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </div>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>
        <standard-dialog :render="!!selected" :value='showModal' :max-width="900" persistent>
            <template v-slot:title>
                <span v-if="selected.id">Edit</span>
                <span v-else>Create</span>
                <span class="ml-1">{{ 'Staff Role' | term }}</span>
            </template>
            <template v-slot:content>
                <v-form ref="form" :lazy-validation="true">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" class="pa-2">
                                <v-text-field
                                    autocomplete="off"
                                    label="Name"
                                    :rules="[$rules.required(), $rules.maxLength(50)]"
                                    v-model="selected.name"
                                    counter="50"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="pa-2">
                                <v-checkbox label="Supervisor?" v-model="selected.isSupervisor"></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-alert
                    v-if='editingSelected && !selected.canDelete && !selected.loadingCanDelete && !selected.isArchived'
                    color='warning'
                    class="white--text">
                    This {{ 'Staff Role' | term }} is in use and cannot be deleted.
                </v-alert>
            </template>
            <template v-slot:actions>
                <v-btn color='red' text v-if='editingSelected && !selected.isArchived' @click='del' :disabled='!selected.canDelete' :loading='selected.loadingCanDelete'>Delete</v-btn>
                <v-btn color="red" text v-if="editingSelected && !selected.isArchived" @click="archiveStaffRole"><v-icon left>mdi-archive-outline</v-icon>Archive</v-btn>
                <v-btn color="red" text v-if="editingSelected && selected.isArchived" @click="restoreStaffRole"><v-icon left>mdi-archive-arrow-up-outline</v-icon>Restore</v-btn>
                <v-spacer></v-spacer>
                <v-btn color='gray' text @click='showModal = false'>Cancel</v-btn>
                <v-btn color='primary' text @click='save()' v-if="!selected.isArchived">Save</v-btn>
            </template>
            <template v-slot:offline-actions>
                <v-spacer></v-spacer>
                <v-btn color='gray' text @click='showModal = false'>Cancel</v-btn>
                <offline-btn text></offline-btn>
            </template>
        </standard-dialog>
        <confirm ref="confirm"></confirm>
    </div>
</template>
<script>
import { EventBus, Events } from '@/lib/EventBus';
import StaffRoles from '@/lib/data/StaffRoles';
import DownloadHelper from '@/lib/DownloadHelper';
import { AdminSandpitVisibility, useSandpitStore } from '@/lib/stores/SandpitStore';
import { mapState } from 'pinia';
import AdminSandpitVisibilitySelector from '@/components/Admin/Sandpit/AdminSandpitVisibilitySelector.vue';
import AdminSandpitItemName from '@/components/Admin/Sandpit/AdminSandpitItemName.vue';
import WeekTimePeriodDisplay from '@/components/Board/TimePeriodDisplay.vue';

export default {
    components: { AdminSandpitVisibilitySelector, AdminSandpitItemName },
    data() {
        return {
            data: null,
            showModal: false,
            selected: null,
            editingSelected: false,
            importDialog: false,
            file: null,
            headers: [
                { text: 'Name', value: 'name' },
                { text: 'Supervisor?', value: 'isSupervisor' },
                { text: '', value: 'id' }
            ]
        };
    },
    async created() {
        await this.loadData();
    },
    destroyed() {},
    methods: {
        async loadData() {
            var data = await StaffRoles.get();

            for (let d of data) {
                d.dirty = false;
            }

            this.data = data;
        },
        create() {
            var displayOrder = this.data.length ? this.data[this.data.length - 1].displayOrder + 1 : 1;
            this.edit({
                name: '',
                displayOrder: displayOrder
            }, true);
        },
        edit(item, creating = false) {
            this.selected = {
                ...item,
                loadingCanDelete: !creating
            };

            if(!creating){
                StaffRoles.canDelete(this.selected.id).then(response => {
                    if(this.selected?.id === item.id)
                        this.selected.canDelete = response.canDelete;
                }).finally(() => {
                    if(this.selected?.id === item.id)
                        this.selected.loadingCanDelete = false;
                });
            }

            this.editingSelected = !creating;

            this.showModal = true;
            setTimeout(() => this.$refs.form.resetValidation(), 1);
        },
        up(item) {
            if (item.displayOrder > 1) {
                item.displayOrder--;
                item.dirty = true;
            }
        },
        down(item) {
            item.displayOrder++;
            item.dirty = true;
        },
        async del() {
            if (await this.$refs.confirm.openAsDeleteResource(this.$termSync('Staff Role'),  { })) {
                await StaffRoles.delete(this.selected.id);
                EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Staff Role')} Deleted`);
                this.showModal = false;
                await this.loadData();
            }
        },
        async archiveStaffRole() {
            if(this.selected == null)
                return;

            this.$wait.start('saving');
            try {
                await StaffRoles.archiveStaffRole(this.selected.id);
                EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Staff Role')} Archived`);
                await this.loadData();
            } finally {
                this.showModal = false;
                this.$wait.end('saving');
            }
        },
        async restoreStaffRole() {
            if(this.selected == null)
                return;

            this.$wait.start('saving');
            try {
                await StaffRoles.restoreStaffRoleFromArchive(this.selected.id);
                EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Staff Role')} Restored`);
                await this.loadData();
            } finally {
                this.showModal = false;
                this.$wait.end('saving');
            }
        },
        async saveList() {
            for (let x of this.data) {
                if (!x.dirty) {
                    continue;
                }
                await StaffRoles.save(x);
            }
            EventBus.$emit(Events.ToastSuccess, `${this.$pluralSync('Staff Roles')} Saved`);
            await this.loadData();
        },
        async save() {
            if (!this.$refs.form.validate()) {
                return;
            }
            await StaffRoles.save(this.selected);
            EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Staff Role')} Saved`);
            this.showModal = false;
            await this.loadData();
        },
        async exportData() {
            const result = await StaffRoles.export();
            var csv = result.csvFile;
            var blob = DownloadHelper.makeBlobFromFileString(csv);
            DownloadHelper.download('StaffRoles.csv', blob);
        },
        async importData() {
            try {
                this.$wait.start('saving');
                this.uploadResult = null;
                await StaffRoles.import(this.file);
                this.importDialog = false;
                await this.loadData();
            } finally {
                this.$wait.end('saving');
            }
        },
        async getFile(e) {
            this.file = e.target.files[0];
        }
    },
    computed: {
        ...mapState(useSandpitStore, ['adminSandpitVisibility']),
        dirty() {
            for (let x of this.data) {
                if (x.dirty) {
                    return true;
                }
            }
            return false;
        },
        sandpitFilter() {
            if(this.adminSandpitVisibility === AdminSandpitVisibility.BOTH)
                return x=>true;
            else if(this.adminSandpitVisibility === AdminSandpitVisibility.ONLY_SANDPIT)
                return x=>x.isSandpitUseOnly;
            else
                return x=>!x.isSandpitUseOnly;
        },
        sortedAndFiltered() {
            return this.data.filter(this.sandpitFilter).sort((a, b) => a.displayOrder - b.displayOrder);
        },
        sortedAndFilteredActive() {
            return this.sortedAndFiltered.filter(x=>!x.isArchived);
        },
        sortedAndFilteredArchived() {
            return this.sortedAndFiltered.filter(x=>x.isArchived);
        }
    }
};
</script>
<style lang="scss" scoped></style>

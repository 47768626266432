<template>
    <div :class="{ 'edit-mode': editing, 'read-mode': !editing }">
        <div class='header-container mt-3 mb-3'>
            <v-card outlined class='w-auto'>
                <div class='w-auto d-flex flex-row align-center pa-2'>
                    <v-select
                        dense
                        v-model='filterEquipment'
                        :items='filterOptions.equipments'
                        label='Equipment'
                        outlined
                        hide-details
                        item-text='value'
                        class='filter-options mr-2'
                        return-object
                    >
                    </v-select>
                    <v-select
                        dense
                        v-model='filterLocation'
                        :items='filterOptions.locations'
                        label='Location'
                        outlined
                        hide-details
                        item-text='value'
                        class='filter-options mr-2'
                        return-object
                    >
                    </v-select>
                    <v-select
                        dense
                        v-model='filterTaskType'
                        :items='filterOptions.taskTypes'
                        label='Task Type'
                        outlined
                        hide-details
                        item-text='value'
                        class='filter-options mr-2'
                        return-object
                    >
                    </v-select>
                    <v-select
                        dense
                        v-model='filterOperator'
                        :items='filterOptions.operators'
                        label='Operator'
                        outlined
                        hide-details
                        item-text='value'
                        class='filter-options mr-2'
                        return-object
                    >
                    </v-select>
                    <v-btn class='filter-button mr-2'
                           outlined
                           @click='loadData(true)'
                           color='primary'>
                        <span v-if='!filterRefresh'>Filter</span>
                        <v-progress-circular v-else indeterminate :size='20' />
                    </v-btn>
                    <v-btn
                        outlined
                        class='clear-filter '
                        @click='clearFilter()'
                        color='primary'>
                        <v-icon class='pa-0 ma-0'>mdi-close</v-icon>
                    </v-btn>
                </div>
            </v-card>
            <div class='d-flex flex-row'>
                <v-switch
                    v-model='showDelays'
                    label='Show Delays'
                    dense
                    hide-details
                    class='my-0 mr-2'
                    key="showDelaysSwitch"
                ></v-switch>
                <v-chip
                    v-if='editing && pageStatus'
                    class='status-chips mr-2'
                    outlined
                    :color="pageStatus === 'Saved' ? 'green' : 'orange'"
                    key="pageStatusChip"
                >
                    {{ pageStatus }}
                </v-chip>
                <v-btn
                    v-if='editing'
                    :disabled='saving'
                    @click='addRow()'
                    color='primary'
                    outlined
                    class='ml-2 '>
                    Add Plod Entry
                </v-btn>
            </div>
        </div>

        <div v-if='dataLoaded && formData.actualsRows.length === 0'
             class='grey lighten-4 pa-5 text-center'>
            No actuals data found matching this shift and filter criteria.
            <div class='mt-5' v-if='editing && filterOptions.taskTypes.length === 1'>
                <v-btn
                    @click='populateShiftData()'
                    color='primary'
                    style='text-align: center'>
                    Populate Data For Shift
                </v-btn>
            </div>
        </div>

        <div v-if='!dataLoaded' class='grey lighten-4 pa-5 text-center'>
            <v-progress-circular
                indeterminate
                color='primary'
            ></v-progress-circular>
        </div>

        <div v-if='dataLoaded && formData.actualsRows.length > 0'
             :class="{ 'high-level-actuals': activeFilteredLocation }" class='table_wrapper ml-15 mr-15'>

            <v-data-table
                :items='filteredActuals'
                :hide-default-footer='true'
                class='custom-table'
                hide-default-header
                :disable-pagination='true'
                :key='tableKey'
                :loading='!dataLoaded'
            >
                <!-- Dynamically generating the headers -->
                <template v-slot:header>
                    <thead>
                    <tr class='header-s'>
                        <th rowspan='2' class='indicator-column header'></th>
                        <th rowspan='2' class='extra-wide header'>
                            <div class='sortable-field' @click="changeSorting('equipment')">
                                <v-icon :color="editing ? 'white' : 'black'" v-if='activeFilteredEquipment'>mdi-filter
                                </v-icon>
                                Equipment
                                <v-icon :color="editing ? 'white' : 'black'" v-if="sortColumn === 'equipment'">
                                    mdi-arrow-{{ sortDirection }}
                                </v-icon>
                            </div>
                        </th>
                        <th rowspan='2' class='extra-wide header'>
                            <div class='sortable-field' @click="changeSorting('location')">
                                <v-icon :color="editing ? 'white' : 'black'" v-if='activeFilteredLocation'>mdi-filter
                                </v-icon>
                                Location
                                <v-icon :color="editing ? 'white' : 'black'" v-if="sortColumn === 'location'">
                                    mdi-arrow-{{ sortDirection }}
                                </v-icon>
                            </div>
                        </th>
                        <th rowspan='2' class='extra-wide header'>
                            <div class='sortable-field' @click="changeSorting('taskType')">
                                <v-icon :color="editing ? 'white' : 'black'" v-if='activeFilteredTaskType'>mdi-filter
                                </v-icon>
                                Task (Type)
                                <v-icon :color="editing ? 'white' : 'black'" v-if="sortColumn === 'taskType'">
                                    mdi-arrow-{{ sortDirection }}
                                </v-icon>
                            </div>
                        </th>
                        <th rowspan='2' class='extra-wide header'>
                            <div class='sortable-field' @click="changeSorting('operator')">
                                <v-icon :color="editing ? 'white' : 'black'" v-if='activeFilteredOperator'>mdi-filter
                                </v-icon>
                                Operator
                                <v-icon :color="editing ? 'white' : 'black'" v-if="sortColumn === 'operator'">
                                    mdi-arrow-{{ sortDirection }}
                                </v-icon>
                            </div>
                        </th>
                        <th rowspan='2' class='extra-wide'>Comments</th>
                        <th rowspan='2' class='col-time'>Start Time</th>
                        <th rowspan='2' class='col-time'>End Time</th>
                        <th class='group-name' v-for='headerGroup in actualsGroups' :key='headerGroup.groupId'
                            :colspan='headerGroup.actualGroupDefinitions.length'>
                            {{ headerGroup.groupName }}
                        </th>
                        <th v-if='editing' rowspan='2' class='actions-column'></th>
                    </tr>
                    <tr class='header-s'>
                        <template v-for='headerGroup in actualsGroups'>
                            <th v-for='headerGroupItem in headerGroup.actualGroupDefinitions' class='subheader'
                                :class='`col-${headerGroupItem.groupDefinitionType.toLowerCase()}`'
                                :key='`${headerGroup.groupId}-${headerGroupItem.groupDefinitionId}`'>
                                {{ headerGroupItem.groupDefinitionName }}
                                <div
                                    v-if="headerGroupItem.groupDefinitionType === 'Formula' && headerGroupItem.formula">
                                    <v-tooltip bottom>
                                        <template v-slot:activator='{ on, attrs }'>
                    <span
                        v-bind='attrs'
                        v-on='on' class='formula-indicator'
                    >Formula</span>
                                        </template>
                                        <span>
                    {{ headerGroupItem.formulaDescription }}
                  </span>
                                    </v-tooltip>
                                </div>
                            </th>
                        </template>
                    </tr>
                    </thead>
                </template>

                <!-- Body of the table -->
                <template v-slot:body='{ items }'>
                    <tbody class='tbody'>
                    <template v-for='item in items'>
                        <tr :key='`${item.id}`'>
                            <td>

                                <v-tooltip bottom
                                           v-if="item.status === 'Error' || (!item.taskTypeId && (!item.taskType || !item.taskType.id))">
                                    <template v-slot:activator='{ on, attrs }'>
                                        <v-icon color='warning'
                                                v-bind='attrs'
                                                v-on='on'>mdi-alert-circle
                                        </v-icon>
                                    </template>
                                    <span>Row will not save until Task Type is selected.</span>
                                </v-tooltip>

                            </td>
                            <td>
                                <v-select
                                    v-model='item.equipment'
                                    dense
                                    outlined
                                    :items='equipmentList'
                                    hide-details
                                    class='small-input fixed-width-combo'
                                    item-text='name'
                                    item-value='id'
                                    :return-object='true'
                                    :readonly='!editing || uiLocked || !!filterEquipment.key'
                                    @input="handleInputChange(item, null, null, 'equipment')"
                                ></v-select>
                            </td>
                            <td>
                                <v-combobox
                                    v-model='item.location'
                                    dense
                                    outlined
                                    :items='locationList'
                                    hide-details
                                    class='small-input fixed-width-combo'
                                    item-text='name'
                                    item-value='id'
                                    :return-object='true'
                                    :readonly='!editing || uiLocked || !!filterLocation.key'
                                    @input="handleInputChange(item, null, null, 'location')"
                                ></v-combobox>
                                <v-text-field
                                    v-if='item.locationDisplayName'
                                    class='small-input mt-3 fixed-width-combo'
                                    v-model='item.locationDisplayName'
                                    hide-details
                                    label='Display Name'
                                    outlined
                                    persistent-placeholder
                                    dense
                                    readonly
                                ></v-text-field>
                            </td>
                            <td>
                                <v-select
                                    v-model='item.taskType'
                                    dense
                                    outlined
                                    :items='permittedTaskTypeList'
                                    hide-details
                                    class='small-input fixed-width-combo'
                                    item-text='name'
                                    item-value='id'
                                    :return-object='true'
                                    :readonly='!editing || uiLocked || !!filterTaskType.key || !item.isCustom'
                                    @input="handleInputChange(item, null, null, 'taskType')"
                                >
                                    <template v-if='!item.isCustom && editing' v-slot:append>
                                        <v-icon class='lock-icon'>mdi-lock</v-icon>
                                    </template>
                                </v-select>
                                <v-text-field
                                    v-if='item.taskTypeDisplayName'
                                    class='small-input mt-3 fixed-width-combo'
                                    v-model='item.taskTypeDisplayName'
                                    hide-details
                                    label='Display Name'
                                    outlined
                                    persistent-placeholder
                                    dense
                                    readonly
                                ></v-text-field>
                            </td>
                            <td>
                                <v-select
                                    v-model='item.operator'
                                    dense
                                    outlined
                                    :items='operatorsList'
                                    hide-details
                                    class='small-input fixed-width-combo'
                                    item-text='name'
                                    item-value='id'
                                    :return-object='true'
                                    :readonly='!editing || uiLocked || !!filterOperator.key'
                                    @input="handleInputChange(item, null, null, 'operator')"
                                ></v-select>
                            </td>
                            <td>
                                <v-textarea
                                    class='small-input'
                                    v-model='item.comment'
                                    outlined
                                    hide-details
                                    single-line
                                    dense
                                    rows='1'
                                    placeholder='Enter comments here...'
                                    :readonly='!editing || uiLocked'
                                    @input='handleInputChange(item)'
                                ></v-textarea>
                            </td>
                            <td>
                                <v-text-field
                                    class='small-input'
                                    v-model='item.startTime'
                                    hide-details
                                    outlined
                                    dense
                                    :readonly='!editing || uiLocked'
                                    type='time'
                                    @input='handleInputChange(item)'
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                    class='small-input'
                                    v-model='item.endTime'
                                    hide-details
                                    outlined
                                    dense
                                    :readonly='!editing || uiLocked'
                                    type='time'
                                    @input='handleInputChange(item)'
                                ></v-text-field>
                            </td>

                            <template v-for='headerGroup in actualsGroups'>
                                <td v-for='headerGroupItem in headerGroup.actualGroupDefinitions'
                                    :key='`${headerGroup.groupId}-${headerGroupItem.groupDefinitionId}`'>
                                    <v-text-field
                                        v-if="headerGroupItem.groupDefinitionType !== 'Boolean' && !valueSets[headerGroupItem.groupDefinitionId]"
                                        class='small-input'
                                        v-model="item.actualsPairs[headerGroup.groupId + '_' + headerGroupItem.groupDefinitionId]"
                                        hide-details
                                        outlined
                                        dense
                                        :disabled='!item.taskType || !headerGroup.taskTypes.some(i => i.taskTypeId === item.taskType.id)'
                                        :readonly="!editing || uiLocked || headerGroupItem.groupDefinitionType === 'Formula'"
                                        :type='getTextFieldType(headerGroupItem.groupDefinitionType)'
                                        v-on='{ keypress: (e) => validateInput(e, headerGroupItem.groupDefinitionType) }'
                                        @input='handleInputChange(item, headerGroupItem, headerGroup)'
                                    ></v-text-field>
                                    <v-select
                                        v-if='valueSets[headerGroupItem.groupDefinitionId]'
                                        v-model="item.actualsPairs[headerGroup.groupId + '_' + headerGroupItem.groupDefinitionId]"
                                        dense
                                        outlined
                                        :disabled='!item.taskType || !headerGroup.taskTypes.some(i => i.taskTypeId === item.taskType.id)'
                                        :items='valueSets[headerGroupItem.groupDefinitionId]'
                                        hide-details
                                        class='small-input'
                                        :readonly='!editing || uiLocked'
                                        @input='handleInputChange(item, headerGroupItem, headerGroup)'
                                    >
                                    </v-select>
                                    <v-checkbox
                                        v-if="headerGroupItem.groupDefinitionType === 'Boolean'"
                                        v-model="item.actualsPairs[headerGroup.groupId + '_' + headerGroupItem.groupDefinitionId]"
                                        class='small-input'
                                        hide-details
                                        :disabled='!item.taskType || !headerGroup.taskTypes.some(i => i.taskTypeId === item.taskType.id)'
                                        :readonly='!editing || uiLocked'
                                        @change='handleInputChange(item, headerGroupItem, headerGroup)'
                                    ></v-checkbox>
                                </td>
                            </template>

                            <td v-if='editing'>
                                <v-btn icon color='red' class='mb-2' @click='deleteActuals(item)'>
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                    <tr v-if='actualsGroups && actualsGroups.length > 0' class='totals-row'>
                        <td></td>
                        <td>
                            <div style='height: 40px; font-weight: bold; align-content: center'>Totals</div>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <template v-for='headerGroup in actualsGroups'>
                            <td v-for='headerGroupItem in headerGroup.actualGroupDefinitions'
                                :key='`${headerGroup.groupId}-${headerGroupItem.groupDefinitionId}`'>
                                <v-text-field
                                    :disabled='!headerGroupItem.summable'
                                    v-model='totals[`${headerGroup.groupId}_${headerGroupItem.groupDefinitionId}`]'
                                    class='small-input'
                                    hide-details
                                    outlined
                                    dense
                                    readonly
                                >
                                </v-text-field>
                            </td>
                        </template>
                        <td></td>
                    </tr>

                    </tbody>
                </template>
            </v-data-table>
        </div>
        <confirm ref='confirm'></confirm>
    </div>
</template>

<script>
import Vue from 'vue';
import Equipment from '@/lib/data/Equipment';
import Actuals from '@/lib/data/Actuals';
import { useSystemStore } from '@/lib/stores/SystemStore';
import _, { update } from 'lodash';
import { mapState } from 'pinia';
import { useShiftDetails } from '@/lib/stores/Shift';
import { v4 as uuidv4 } from 'uuid';
import { DateFormat } from '@/plugins/dates';
import { useDepartmentStore } from '@/lib/stores/DepartmentStore';
import { EventBus, Events } from '@/lib/EventBus';
import Logging from '@/lib/Logging';
import dayjs from 'dayjs';

export default Vue.extend({
    props: ['editing'],
    computed: {
        ...mapState(useDepartmentStore, ['departmentId', 'locations', 'locationName', 'taskTypes']),
        ...mapState(useSystemStore, ['staffMembers']),
        ...mapState(useShiftDetails, ['shiftId', 'shiftName', 'shiftStartTime']),
        filteredActuals() {
            if(this.showDelays) {
                return this.formData.actualsRows;
            } else {
                const delayTaskTypes = this.taskTypes.filter(tt => tt.isDelay).map(tt => tt.id);

                return this.formData.actualsRows.filter(row => !delayTaskTypes.includes(row.taskTypeId));
            }
        },
        permittedTaskTypeList() {
            return this.taskTypeList.filter(tt => !tt.isArchived || this.formData.actualsRows?.some(x=>x.taskTypeId === tt.id));
        }
    },
    data() {
        return {
            uiLocked: false,
            dataLoaded: false,
            entriesColumnCount: 0,
            formData: {
                actualsRows: []
            },
            actualsGroups: [],
            equipmentList: null,
            locationList: null,
            taskTypeList: null,
            operatorsList: null,
            pageStatus: '',
            saving: false,
            debouncedSaveFn: null,
            formulaDependencies: [],
            totals: {},
            tableKey: 0,  // Add this counter to force the table to re-render when the data changes,
            definitionsIndex: [], // For quick look up of definitions
            shiftIdentifier: '',
            filterOptions: {
                equipments: [],
                locations: [],
                taskTypes: [],
                operators: []
            },
            filterEquipment: null,
            filterLocation: null,
            filterTaskType: null,
            filterOperator: null,
            activeFilteredEquipment: null,
            activeFilteredLocation: null,
            activeFilteredTaskType: null,
            activeFilteredOperator: null,
            filterRefresh: false,
            showDelays: true,
            sortColumn: 'taskType',
            sortDirection: 'up',
            filterOptionsAll: { key: null, value: 'All' },
            comboUnknownOption: { id: 'unknown', name: 'Unknown' },
            tableHeight: null,
            valueSets: {}
        };
    },
    created() {
        this.debouncedSaveFn = _.debounce(() => {
            if (!this.saving) {
                this.save();
            }
        }, 5000);

        this.filterEquipment = this.filterOptionsAll;
        this.filterLocation = this.filterOptionsAll;
        this.filterTaskType = this.filterOptionsAll;
        this.filterOperator = this.filterOptionsAll;

        this.loadData();
    },
    mounted() {

    },
    methods: {
        toggleMode() {
            this.$emit('custom-event');
        },
        async deleteActuals(row) {
            if (await this.$refs.confirm.openAsDeleteResource(this.$termSync('Actuals Entry'), {})) {
                await Actuals.deleteActuals(this.departmentId, row.id);
                this.formData.actualsRows = this.formData.actualsRows.filter(r => r.id !== row.id);
                EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Actuals Entry')} Deleted`);
            }
        },
        changeSorting(column) {
            if (this.sortColumn === column) {
                this.sortDirection = this.sortDirection === 'up' ? 'down' : 'up';
            } else {
                this.sortColumn = column;
                this.sortDirection = 'up';
            }

            this.sort(this.formData.actualsRows);
        },
        sort(actualsRows) {
            actualsRows.sort((a, b) => {

                let valueA = '';
                let valueB = '';

                if (this.sortColumn === 'equipment') {
                    valueA = a.equipment?.name ?? '';
                    valueB = b.equipment?.name ?? '';
                } else if (this.sortColumn === 'location') {
                    valueA = typeof a.location === 'string' ? a.location : a.location?.name ?? '';
                    valueB = typeof b.location === 'string' ? b.location : b.location?.name ?? '';
                } else if (this.sortColumn === 'taskType') {
                    valueA = a.taskType?.name ?? '';
                    valueB = b.taskType?.name ?? '';
                } else if (this.sortColumn === 'operator') {
                    valueA = a.operator?.name ?? '';
                    valueB = b.operator?.name ?? '';
                }

                if (this.sortDirection === 'up')
                    return valueA.localeCompare(valueB);
                else
                    return valueB.localeCompare(valueA);
            });
        },
        getShiftName() {
            return `${this.shiftName} - ${dayjs(this.shiftStartTime).format(DateFormat.WeekDisplay)}`;
        },
        async populateShiftData() {
            this.$wait.start('loading data');

            try {
                await Actuals.populateFromShiftPlan(this.departmentId, this.shiftId);

                EventBus.$emit(Events.ToastSuccess, `Actuals populated from shift plan`);

                await this.loadData();
            } catch (e) {
                Logging.error(e);
                EventBus.$emit(Events.ToastError, `Error populating actuals from shift plan: ${e.message}`);
            } finally {
                this.$wait.end('loading data');
            }
        },
        processFormulasInDefinitions() {
            for (let g = 0; g < this.actualsGroups.length; g++) {
                let currentGroup = this.actualsGroups[g];

                for (let d = 0; d < currentGroup.actualGroupDefinitions.length; d++) {
                    let item = currentGroup.actualGroupDefinitions[d];

                    if (item.groupDefinitionType !== 'Formula' || !item.formula)
                        continue;

                    item.formulaDecimalPlaces = item.decimalPlaces;
                    let lowercaseFormula = item.formula.toLowerCase();
                    item.processedFormula = lowercaseFormula;

                    const regex = /\[(.*?)\]/g;
                    item.isFormulaProcessed = true;

                    for (const match of lowercaseFormula.matchAll(regex)) {
                        let definitionName = match[1];

                        const foundDefinition = currentGroup.actualGroupDefinitions.find(i => i.groupDefinitionName.toLowerCase() === definitionName.toLowerCase());
                        if (!foundDefinition) {
                            item.isFormulaProcessed = false;
                            break;
                        }

                        let definitionId = foundDefinition.groupDefinitionId;

                        // Add to formulaDependencies to know which column to trigger recalculation
                        if (!this.formulaDependencies.includes(`${currentGroup.groupId}_${definitionId}`))
                            this.formulaDependencies.push(`${currentGroup.groupId}_${definitionId}`);

                        item.processedFormula = item.processedFormula.replace(`[${definitionName}]`, `[${currentGroup.groupId}_${definitionId}]`);
                    }

                    if (item.isFormulaProcessed)
                        item.formulaDescription = item.formula;
                    else {
                        Logging.warning('Error processing formula', item.formula);
                        item.formulaDescription = 'Error processing formula: ' + item.formula;
                    }
                }
            }
        },
        async taskTypeChanged() {
            const uniqueTaskTypeIds = [...new Set(
                this.formData.actualsRows
                    .map(row => row.isCustom ? row.taskType?.id : row.taskTypeId)
                    .filter(Boolean) // Remove falsy values
            )];

            this.actualsGroups = await Actuals.getGroups(this.departmentId, uniqueTaskTypeIds);

            this.processFormulasInDefinitions();

            // Trigger table to re-render
            this.tableKey += 1;
        },
        handleMetresAdvancedChanged() {
            this.metresAdvancedChanged = true;
            this.pageStatus = 'Not Saved';
            this.debouncedSaveFn();
        },
        handleInputChange(row, actualsChanged, headerGroup, target) {
            switch (target) {
                case 'taskType':
                    row.taskTypeId = row.taskType.id;
                    this.taskTypeChanged();
                    break;
                case 'operator':
                    row.operatorId = row.operator.id;
                    break;
                case 'location':
                    row.locationId = row.location.id;
                    break;
                case 'equipment':
                    row.equipmentId = row.equipment.id;
                    break;
            }

            if (actualsChanged && this.formulaDependencies.includes(`${headerGroup.groupId.toLowerCase()}_${actualsChanged.groupDefinitionId.toLowerCase()}`)) {
                this.recalculateFormulaForRow(row);
            }

            this.recalculateTotals();

            row.status = 'Not Saved';
            this.pageStatus = 'Not Saved';
            this.debouncedSaveFn();
        },
        recalculateTotals() {
            // TODO: Optimize recalculation of totals to limit it to only the changed column (taking into account formula recalculations)

            for (let g = 0; g < this.actualsGroups.length; g++) {
                let currentGroup = this.actualsGroups[g];

                let lowercasedGroupId = currentGroup.groupId.toLowerCase();

                for (let d = 0; d < currentGroup.actualGroupDefinitions.length; d++) {
                    let currentDefinition = currentGroup.actualGroupDefinitions[d];

                    if (!currentDefinition.summable || (
                        currentDefinition.groupDefinitionType !== 'Decimal' && currentDefinition.groupDefinitionType !== 'Integer' && currentDefinition.groupDefinitionType !== 'Formula'
                    ))
                        continue;

                    let cellId = `${lowercasedGroupId}_${currentDefinition.groupDefinitionId}`;
                    let currentTotal = 0;

                    this.formData.actualsRows.map(r => {
                        if (r.actualsPairs[cellId] !== undefined)
                            currentTotal += +r.actualsPairs[cellId];
                    });

                    let decimalPlaces = currentDefinition.decimalPlaces ? currentDefinition.decimalPlaces : 1;
                    if (currentDefinition.groupDefinitionType === 'Formula' || currentDefinition.groupDefinitionType === 'Decimal') {
                        currentTotal = currentTotal.toFixed(decimalPlaces);
                    }

                    this.totals[cellId] = currentTotal;
                }
            }
        },
        recalculateFormulaForRow(row) {
            // Have to do it from actualsGroups because actualValues only come back when there are values
            for (let g = 0; g < this.actualsGroups.length; g++) {
                let currentGroup = this.actualsGroups[g];

                // Do not continue with group if not associated with the taskType
                if (!currentGroup.taskTypes.some(tt => tt.taskTypeId === row.taskType.id))
                    continue;

                let lowercasedGroupId = currentGroup.groupId.toLowerCase();

                for (let d = 0; d < currentGroup.actualGroupDefinitions.length; d++) {
                    let currentDefinition = currentGroup.actualGroupDefinitions[d];

                    if (currentDefinition.groupDefinitionType === 'Formula' && currentDefinition.isFormulaProcessed) {

                        const regex = /\[(.*?)\]/g;

                        let formulaExpression = currentDefinition.processedFormula;

                        let hasUnmatched = false;
                        for (const match of formulaExpression.matchAll(regex)) {
                            let matchedValue = row.actualsPairs[match[1]];

                            if (matchedValue === undefined)
                                hasUnmatched = true;

                            formulaExpression = formulaExpression.replace(`[${match[1]}]`, `${matchedValue}`);
                        }

                        // Check that all [x] expressions have been found
                        if (formulaExpression.indexOf('[') < 0 && !hasUnmatched) {
                            let evaluatedResult = eval(formulaExpression).toFixed(currentDefinition.formulaDecimalPlaces);

                            // If we want to remove trailing 0s
                            // evaluatedResult = parseFloat(evaluatedResult);

                            row.actualsPairs[lowercasedGroupId + '_' + currentDefinition.groupDefinitionId.toLowerCase()] = evaluatedResult;
                        }
                    }
                }
            }
        },
        async save() {
            if (this.pageStatus === 'Error')
                return false;

            let saveError = false;

            // Process and save all actuals rows that have been modified
            let updateCommands = [];
            let rowsToSave = this.formData.actualsRows.filter(i => i.status === 'Not Saved' && i.taskType);

            rowsToSave.map(rowToSave => {
                let updateCommand = {
                    id: rowToSave.id,
                    equipmentId: rowToSave.equipment ? rowToSave.equipment.id : null,
                    operatorId: rowToSave.operator ? rowToSave.operator.id : null,
                    taskTypeId: rowToSave.taskType ? rowToSave.taskType.id : null,
                    taskTypeDisplayName: rowToSave.taskTypeDisplayName,
                    comment: rowToSave.comment,
                    startTime: rowToSave.startTime,
                    endTime: rowToSave.endTime,
                    isCustom: rowToSave.isCustom,
                    actualGroupDefinitions: []
                };

                // Check if location is a custom string, otherwise set to what is in combobox
                if (rowToSave.location && typeof rowToSave.location === 'string') {
                    updateCommand.locationId = null;
                    updateCommand.locationDisplayName = rowToSave.location;
                } else {
                    updateCommand.locationDisplayName = null;
                    updateCommand.locationId = rowToSave.location ? rowToSave.location.id : null;
                }

                // Set any combo box 'unknown' values to unknown flag
                if (updateCommand.equipmentId === 'unknown') {
                    updateCommand.equipmentId = null;
                    updateCommand.isEquipmentUnknown = true;
                }
                if (updateCommand.locationId === 'unknown') {
                    updateCommand.locationId = null;
                    updateCommand.isLocationUnknown = true;
                }
                if (updateCommand.operatorId === 'unknown') {
                    updateCommand.operatorId = null;
                    updateCommand.isOperatorUnknown = true;
                }

                for (let g = 0; g < this.actualsGroups.length; g++) {
                    let currentGroup = this.actualsGroups[g];

                    if (currentGroup.taskTypes.some(tt => tt.taskTypeId === rowToSave.taskType.id)) {
                        let lowercasedGroupId = currentGroup.groupId.toLowerCase();

                        for (let d = 0; d < currentGroup.actualGroupDefinitions.length; d++) {
                            let currentDefinition = currentGroup.actualGroupDefinitions[d];

                            // Do not add if there are no recorded values
                            if (!rowToSave.actualsPairs[`${lowercasedGroupId}_${currentDefinition.groupDefinitionId}`])
                                continue;

                            let defValue = {
                                actualsGroupDefinitionId: currentDefinition.groupDefinitionId,
                                actualsGroupId: lowercasedGroupId,
                                value: rowToSave.actualsPairs[`${lowercasedGroupId}_${currentDefinition.groupDefinitionId}`],
                                type: this.getTypeStringAsNumber(currentDefinition.groupDefinitionType)
                            };

                            updateCommand.actualGroupDefinitions.push(defValue);
                        }
                    }
                }

                updateCommands.push(updateCommand);
            });

            if (updateCommands.length > 0) {
                this.uiLocked = true;
                this.saving = true;
                this.pageStatus = 'Saving...';

                try {
                    await Actuals.update(this.departmentId, this.shiftId, updateCommands);
                } catch (e) {
                    saveError = true;
                    Logging.error(e);

                    rowsToSave.map(i => {
                        i.status = 'Error';
                    });
                } finally {
                    this.saving = false;
                    this.uiLocked = false;

                    if (!saveError) {
                        this.pageStatus = 'Saved';

                        rowsToSave.map(i => {
                            i.status = '';
                        });
                    } else
                        this.pageStatus = 'Error';
                }
            }

            return !saveError;
        },
        getTypeStringAsNumber(typeString) {
            switch (typeString) {
                case 'Integer':
                    return 0;
                case 'Decimal':
                    return 1;
                case 'Time':
                    return 2;
                case 'Text':
                    return 3;
                case 'Formula':
                    return 4;
                case 'Boolean':
                    return 5;
            }
        },
        validateInput(event, dataType) {
            let newValue = event.target.value + event.key;

            let validationRegex = null;

            if (dataType === 'Integer')
                validationRegex = /^-?\d+$/;
            else if (dataType === 'Decimal')
                validationRegex = /^-?\d*\.?\d*$/;

            if (validationRegex && !validationRegex.test(newValue)) {
                event.preventDefault();
            }
        },
        switchToEdit() {
            this.editing = true;
            this.uiLocked = false;
            this.pageStatus = '';
        },
        finishEditing() {
            if (this.pageStatus === 'Not Saved') {
                if (this.debouncedSaveFn)
                    this.debouncedSaveFn.cancel();

                this.save(true);
            } else {
                this.editing = false;
                this.uiLocked = true;
            }
        },
        addRow() {
            let newItem = {
                id: uuidv4(),
                actualsPairs: {},
                status: 'Not Saved',
                isCustom: true,
                equipment: undefined,
                location: undefined,
                operator: undefined,
                taskType: undefined
            };

            // Set item default values that are uneditable, if filter has been applied

            if (this.filterEquipment)
                newItem.equipment = this.equipmentList.find(i => i.id === this.filterEquipment?.key?.toLowerCase());

            if (this.filterLocation)
                newItem.location = this.locationList.find(i => i.id === this.filterLocation?.key?.toLowerCase());

            if (this.filterOperator)
                newItem.operator = this.operatorsList.find(i => i.id === this.filterOperator?.key?.toLowerCase());

            if (this.filterTaskType)
                newItem.taskType = this.taskTypeList.find(i => i.id === this.filterTaskType?.key?.toLowerCase());

            this.formData.actualsRows.push(newItem);
        },
        clearFilter() {
            this.filterEquipment = this.filterOptionsAll;
            this.filterLocation = this.filterOptionsAll;
            this.filterTaskType = this.filterOptionsAll;
            this.filterOperator = this.filterOptionsAll;

            this.activeFilteredEquipment = null;
            this.activeFilteredLocation = null;
            this.activeFilteredTaskType = null;
            this.activeFilteredOperator = null;

            this.loadData(true);
        },
        async loadData(filterRefresh = false) {
            if (!this.csvExportDateRangeStart && this.shiftStartTime)
                this.csvExportDateRangeStart = this.shiftStartTime.format('YYYY-MM-DD');

            if (!this.csvExportDateRangeEnd && this.shiftStartTime)
                this.csvExportDateRangeEnd = this.shiftStartTime.format('YYYY-MM-DD');

            this.filterRefresh = filterRefresh;

            this.shiftIdentifier = this.getShiftName();

            this.equipmentList = [this.comboUnknownOption, ...await Equipment.get(['EquipmentRole', 'Departments'])];
            this.locationList = [this.comboUnknownOption, ...this.locations];
            this.taskTypeList = this.taskTypes;
            this.operatorsList = [this.comboUnknownOption, ...this.staffMembers];

            const options = await Actuals.getFilterOptionsForDepartmentShiftId(this.departmentId, this.shiftId);

            this.filterOptions.locations = [this.filterOptionsAll, ...this.locations.map(l => {
                return { key: l.id, value: l.name };
            })];
            this.filterOptions.equipments = [this.filterOptionsAll, ...options.equipments];
            this.filterOptions.taskTypes = [this.filterOptionsAll, ...options.taskTypes];
            this.filterOptions.operators = [this.filterOptionsAll, ...options.operators];

            let actualsRows = await Actuals.getActualsDataForDepartmentShiftId(
                this.departmentId,
                this.shiftId,
                this.filterTaskType?.key,
                this.filterOperator?.key,
                this.filterEquipment?.key,
                this.filterLocation?.key
            );

            // Get unique taskTypeIds from actuals data
            const uniqueTaskTypeIds = Array.from(
                new Set(actualsRows.flatMap(a => a.taskTypeId))
            );

            // Get groups
            if (uniqueTaskTypeIds && uniqueTaskTypeIds.length > 0) {
                this.actualsGroups = await Actuals.getGroups(this.departmentId, uniqueTaskTypeIds);

                // Add empty value as option for value sets
                this.actualsGroups?.map(g => {
                    g.actualGroupDefinitions?.map(d => {
                        if (d.valueSet && d.valueSet.length >= 1) {
                            this.valueSets[d.groupDefinitionId] = ['', ...d.valueSet];
                        }
                    });
                });
            }

            this.processFormulasInDefinitions();

            // Once mounted, massage data
            actualsRows.map(row => {
                // 1. Change time format to HH:mm
                row.actualValues.map(item => {
                    if (item.type === 'Time' && item.value) {
                        // Convert to HH:mm
                        let date = new Date(item.value);
                        item.value = date.toISOString().substr(11, 5);
                    }

                    if (item.value && (item.type === 'Decimal' || item.type === 'Formula') && item.decimalPlaces)
                        item.value = item.value.toFixed(item.decimalPlaces);
                    else if (item.type === 'Integer')
                        item.value = item.value.toString();
                });

                // 2. Reduce actuals data to a key value pair, with ID being a concatenation of groupId and defId
                row.actualsPairs = row.actualValues.reduce((acc, item) => {
                    acc[`${item.groupId.toLowerCase()}_${item.actualValueId.toLowerCase()}`] = item.value;

                    return acc;
                }, {});

                // 3. Massage equipment, location, taskType and operator into objects needed for combo boxes
                if (row.isEquipmentUnknown)
                    row.equipment = this.comboUnknownOption;
                else
                    row.equipment = {
                        id: row.equipmentId,
                        name: row.equipmentName
                    };

                row.taskType = {
                    id: row.taskTypeId,
                    name: row.taskTypeName
                };

                if (row.isLocationUnknown)
                    row.location = this.comboUnknownOption;
                else if (!row.locationId && row.locationDisplayName)
                    row.location = row.locationDisplayName;
                else
                    row.location = {
                        id: row.locationId,
                        name: row.locationName
                    };

                if (row.isOperatorUnknown)
                    row.operator = this.comboUnknownOption;
                else
                    row.operator = {
                        id: row.operatorId,
                        name: row.operatorName
                    };
            });

            this.sort(actualsRows);

            this.formData.actualsRows = actualsRows;

            // Calculate formula for each row
            this.formData.actualsRows.map(row => {
                this.recalculateFormulaForRow(row);
            });

            // Calculate totals
            this.recalculateTotals();

            if (this.filterEquipment.key)
                this.activeFilteredEquipment = this.filterEquipment;
            if (this.filterLocation.key)
                this.activeFilteredLocation = this.filterLocation;
            if (this.filterTaskType.key)
                this.activeFilteredTaskType = this.filterTaskType;
            if (this.filterOperator.key)
                this.activeFilteredOperator = this.filterOperator;

            this.dataLoaded = true;
            this.filterRefresh = false;
        },
        getMetresAdvancedByLocationId(locationId) {
            const selectedHighLevelActuals = this.supervisorShiftReport?.highLevelActuals?.find(i => i.locationId?.toLowerCase() === locationId?.toLowerCase());
            const selectedLocationShiftPrediction = this.shiftPredictions?.find(i => i.locationId?.toLowerCase() === locationId?.toLowerCase());

            if (selectedHighLevelActuals)
                return {
                    highLevelActualsId: selectedHighLevelActuals.id,
                    metresAdvancedPlanned: selectedHighLevelActuals.plannedMetresAdvanced,
                    metresAdvancedSupervisor: selectedHighLevelActuals.supervisorMetresAdvanced,
                    metresAdvancedActual: selectedHighLevelActuals.actualMetresAdvanced
                };
            else
                return {
                    metresAdvancedPlanned: selectedLocationShiftPrediction?.plannedMetresAdvanced
                };
        },
        getTextFieldType(type) {
            if (type === 'Time')
                return 'time';
            else
                return 'text';
        }
    }
});
</script>


<style scoped>
.totals-row > td {
    border-top: 3px SOLID #EEE !important;
}

.edit-mode .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    color: #FFF !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    border-bottom: 1px SOLID #FFF;
}

.col-boolean {
    min-width: 50px;
}

.col-decimal {
    min-width: 70px;
}

.col-integer {
    min-width: 70px;
}

.col-formula {
    min-width: 70px;
}

.col-text {
    min-width: 100px;
}

.col-time {
    min-width: 120px;
}

.grey-box {
    background-color: #EEE;
}

.formula-indicator {
    color: darkorange;
    font-style: italic;
}

.header-s {
    font-size: 12px;
}

.header-s .edit-mode {
    background-color: #15A69A;
}

.header-s .read-mode {
    background-color: #DDD;
}

.custom-table th,
.custom-table td {
    border: 1px solid #FFF;
    padding: 8px 5px !important;
    height: auto !important;
    text-align: center !important;
}

.custom-table {
    & table {
        /*
        Note: collapse doesn't quite work at this point because the table border gets rendered behind the cells and shifts.
        border-collapse: collapse;
        */
    }
}

.custom-table {
    & td {
        border: 0 solid #FFF;
        padding: 10px 5px 10px 5px !important;
        text-align: center;
    }
}

.fixed-width-combo {
    max-width: 140px;
}

.extra-wide {
    min-width: 150px;
    max-width: 150px;
}

.actions-column {
    width: 50px;
}

.indicator-column {
    min-width: 50px;
}

.group-name {
    font-style: italic;
}

.small-input {
    font-size: 14px;
    margin-top: 0;
}

.add-new-row-button {
    margin-left: 10px;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table_wrapper {
    overflow: auto;
    max-width: 100%;

    position: fixed;
    top: 244px;
    bottom: 0;
    left: 0;
    right: 0;
}

.high-level-actuals {
    top: 385px;
}

.v-input--is-disabled {
    opacity: 0.4;
}

.lock-icon {
    color: #CCC;
}

.status-chips {
    font-weight: bold;
}

.sortable-field {
    cursor: pointer;
}

.filter-button {
    width: 90px;
}

.clear-filter {
    min-width: 40px !important;
    width: 40px;
}

.filter-options {
    width: 170px;
}

input[readonly] {
    font-style: italic;
    color: #777;
}

tbody tr:hover {
    background-color: transparent !important;
}

/* 
Make table headers sticky
*/

/* Targets main 5 stick headers */
th.header:nth-child(-n+5) {
    position: sticky;
    z-index: 99;
    top: 0;
}

/* Targets all other top row header cells */
th:nth-child(n+6):not(.subheader) {
    position: sticky;
    z-index: 90;
    top: 0;
}

/* Targets all subheader cells */
th.subheader {
    position: sticky;
    z-index: 90;
    top: 35px;
}

.edit-mode th.header:nth-child(-n+5),
.edit-mode th.subheader,
.edit-mode th:nth-child(n+6):not(.subheader) {
    background-color: #15A69A;
}

.read-mode th.header:nth-child(-n+5),
.read-mode th.subheader,
.read-mode th:nth-child(n+6):not(.subheader) {
    background-color: #D1D1D1;
}

/* Targets the first 5 columns of the body of table */
td:nth-child(-n+5) {
    position: sticky;
    background-color: #FFF; /* Ensure the background isn't transparent */
    z-index: 10; /* Ensure sticky columns appear above other cells */
}

/* Set the left position for each sticky column */
th:nth-child(1),
td:nth-child(1) {
    left: 0;
}

th:nth-child(2),
td:nth-child(2) {
    left: 50px;
}

th:nth-child(3),
td:nth-child(3) {
    left: 200px;
}

th:nth-child(4),
td:nth-child(4) {
    left: 350px;
}

th:nth-child(5),
td:nth-child(5) {
    left: 500px;
}

</style>
import { GenericDataApi } from '@/lib/data/GenericDataApi';
import { EquipmentRole } from '@/models/api/Data/equipment-role';
import AppHttp from '../AppHttp';
import { CanDeleteEquipmentRoleResponse } from '@/models/api';

export class EquipmentRoles extends GenericDataApi<EquipmentRole> {
    constructor() {
        super('EquipmentRoles');
    }
    public async export() {
        const response = await AppHttp.get(`${this.resource}/export`);
        const data = response ? response.data : null;
        return data;
    }
    public async import(file: any) {
        const response = await AppHttp.postWithFiles(`${this.resource}/import`, {}, [file]);
        const data = response ? response.data : null;
        return data;
    }

    public async archiveEquipmentRole(id: string) {
        const response = await AppHttp.put(`${this.resource}/${id}/Archive`, null);
        const data = response ? response.data : null;
        return data;
    }

    public async restoreEquipmentRoleFromArchive(id: string) {
        const response = await AppHttp.put(`${this.resource}/${id}/Restore`, null);
        const data = response ? response.data : null;
        return data;
    }

    public async canDelete(id: string): Promise<CanDeleteEquipmentRoleResponse> {
        const response = await AppHttp.get(`${this.resource}/${id}/CanDelete`);

        const data = response ? response.data : null;
        return data;
    }
}

export default new EquipmentRoles();

import AppHttp from '@/lib/AppHttp';
import {
    CanDeleteCycleResponse,
    CanDeleteEquipmentResponse,
    CreateCycleCommand,
    Cycle,
    UpdateCycleCommand
} from '@/models/api';

export class Cycles {
    private resource: string = 'Cycles';

    public async export(departmentId: string) {
        const response = await AppHttp.get(`Cycles/export/${departmentId}`);
        const data = response ? response.data : null;
        return data;
    }
    public async import(departmentId: string, file: any) {
        const response = await AppHttp.postWithFiles(`Cycles/import/${departmentId}`, {}, [file]);
        const data = response ? response.data : null;
        return data;
    }

    public async get(include?: string[]): Promise<Cycle[]> {
        let query = '';
        if (include) {
            const p: string = include.map((x) => `include=${x}`).join('&');
            query = '?' + p;
        }
        const response = await AppHttp.get(this.resource + query);
        const data = response ? response.data : null;
        return data;
    }

    public async update(command: UpdateCycleCommand) {
        const response = await AppHttp.post(`${this.resource}/Update`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async create(command: CreateCycleCommand) {
        const response = await AppHttp.post(`${this.resource}/Create`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async delete(id: string) {
        const response = await AppHttp.delete(`${this.resource}/${id}`);
        const data = response ? response.data : null;
        return data;
    }

    public async archiveCycle(id: string) {
        const response = await AppHttp.put(`${this.resource}/${id}/Archive`, null);
        const data = response ? response.data : null;
        return data;
    }

    public async restoreCycleFromArchive(id: string) {
        const response = await AppHttp.put(`${this.resource}/${id}/Restore`, null);
        const data = response ? response.data : null;
        return data;
    }

    public async canDelete(id: string): Promise<CanDeleteCycleResponse> {
        const response = await AppHttp.get(`${this.resource}/${id}/CanDelete`);

        const data = response ? response.data : null;
        return data;
    }

    public async getByDepartment(departmentId: string): Promise<Cycle[]> {
        const response = await AppHttp.get(`${this.resource}/GetForDepartment?departmentId=${departmentId}`);

        const data = response ? response.data : null;
        return data;
    }
}

export default new Cycles();

import { GenericDataApi } from '@/lib/data/GenericDataApi';
import { AxiosError } from 'axios';
import AppHttp from '../AppHttp';
import Errors from '../Errors';
import { CanDeleteEquipmentResponse, Equipment, HasLocationGotPlannedWorkQueryResponse } from '@/models/api';

export class EquipmentApi extends GenericDataApi<Equipment> {
    constructor() {
        super('Equipment');
    }
    public async export() {
        const response = await AppHttp.get(`${this.resource}/export`);
        const data = response ? response.data : null;
        return data;
    }
    public async import(file: any) {
        const response = await AppHttp.postWithFiles(`${this.resource}/import`, {}, [file]);
        const data = response ? response.data : null;
        return data;
    }
    async saveEquipment(model: any) {
        const response = await AppHttp.post(`${this.resource}/save`, model);
        const data = response ? response.data : null;
        return data;
    }

    public async archiveEquipment(id: string) {
        const response = await AppHttp.put(`${this.resource}/${id}/Archive`, null);
        const data = response ? response.data : null;
        return data;
    }

    public async restoreEquipmentFromArchive(id: string) {
        const response = await AppHttp.put(`${this.resource}/${id}/Restore`, null);
        const data = response ? response.data : null;
        return data;
    }

    public async canDelete(id: string): Promise<CanDeleteEquipmentResponse> {
        const response = await AppHttp.get(`${this.resource}/${id}/CanDelete`);

        const data = response ? response.data : null;
        return data;
    }
}

export default new EquipmentApi();

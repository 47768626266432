import { GenericDataApi } from '@/lib/data/GenericDataApi';
import { AxiosError } from 'axios';
import AppHttp from '../AppHttp';
import Errors from '../Errors';
import { CanDeleteStaffRoleResponse, CanDeleteTaskTypeResponse, StaffRole } from '@/models/api';

export class StaffRoles extends GenericDataApi<StaffRole> {
    constructor() {
        super('StaffRoles');
    }

    public async export() {
        const response = await AppHttp.get(`${this.resource}/export`);
        const data = response ? response.data : null;
        return data;
    }

    public async import(file: any) {
        const response = await AppHttp.postWithFiles(`${this.resource}/import`, {}, [file]);
        const data = response ? response.data : null;
        return data;
    }

    public async archiveStaffRole(id: string) {
        const response = await AppHttp.put(`${this.resource}/${id}/Archive`, null);
        const data = response ? response.data : null;
        return data;
    }

    public async restoreStaffRoleFromArchive(id: string) {
        const response = await AppHttp.put(`${this.resource}/${id}/Restore`, null);
        const data = response ? response.data : null;
        return data;
    }

    public async canDelete(id: string): Promise<CanDeleteStaffRoleResponse> {
        const response = await AppHttp.get(`${this.resource}/${id}/CanDelete`);

        const data = response ? response.data : null;
        return data;
    }
}

export default new StaffRoles();
